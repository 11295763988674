import React from 'react';

import Paragraph from '@components/paragraph';
import ViewContainer from '@components/viewContainer';

const Club = (): React.ReactElement => {
    return (
        <ViewContainer>
            <h1 className="title is-4">Kenshikai Karate-Do</h1>
            <Paragraph>
                Hokama sensein Kenshikai karate- ja kobudotyylin toivat Okinawalta Suomeen senseit
                Kimmo Viitaharju (Kenshikai Karate-Do) ja Mikki Korhonen (Kenshikai Finland).
            </Paragraph>
            <Paragraph>
                Kenshikai Karate-Don periaatteena on treenata kovaa, mutta rennossa ilmapiirissä.
                Pääosa harjoittelustamme käsittelee karaten aseettomia tekniikoita.
            </Paragraph>
            <Paragraph>
                Pidämme karateharjoittelumme hyvin käytännönläheisenä: pääpainona on katoista eli
                liikesarjoista sovelletut itsepuolustustekniikat (bunkai) sekä erilaiset vapaaseen
                kamppailuun (sparraus) liittyvät harjoitukset.
            </Paragraph>
            <Paragraph>
                Pääajatuksenamme on, että tekniikoiden toimivuus on tyyliä tärkeämpää. Näin ollen
                seurassamme on helppo aloittaa jos sinulta löytyy jo valmiiksi kokemusta
                kamppailulajeista.
            </Paragraph>
            <Paragraph>
                Jos kiinnostuit liittymään seuraamme, niin helpointa on vain osallistua normaaleihin
                harjoituksiimme. Ota yhteyttä niin sovimme kanssasi sopivan ajankohdan.
            </Paragraph>
        </ViewContainer>
    );
};

export default Club;
